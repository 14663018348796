/**
 * This should be called when moving encounters to offline storage.
 * It should ensure that the user exists
 */
import {NitTools} from "../../../resources/classes/NursitTools";
import * as environment from "../../../../config/environment.json";
import {FhirService} from "../../../resources/services/FhirService";
import {inject} from "aurelia-framework";
import {SmileService} from "../../../resources/services/SmileService";
import {LocationService} from "../../../resources/services/LocationService";
import {Router} from "aurelia-router";
import {DialogMessages} from "../../../resources/services/DialogMessages";
import {I18N} from "aurelia-i18n";
import {User} from './user';
import {ConfigService} from "resources/services/ConfigService";

@inject(Router, DialogMessages, SmileService, I18N, FhirService, LocationService)

export class CreateWithAjaxCall {
    userString: string = 'NOT SET!';
    rootString: string = 'NOT SET';
    userName: string;
    userPass: string;
    firstName: string;
    lastName: string;
    userExists: boolean = false;
    userClass: User;

    constructor(router, dialogMessages, smileService, i18n, fhirService: FhirService, locationService: LocationService) {
        this.userClass = new User(router, dialogMessages, smileService, i18n, fhirService, locationService);
    }

    debug(s: string) {
        if (!ConfigService.Debug) return;
        const logItem = document.getElementById('log');
        console.debug(s);
        if (logItem)
            logItem.append(s + '\n');
    }

    async activate(params) {
        // backup SessionStorage
        let tmp = {};
        Object.keys(sessionStorage).forEach(key => {
            this.debug("Added Session Key " + key + " to backup");
            tmp[key] = sessionStorage[key];
        });


        this.userString = atob(decodeURIComponent(params.userString));
        let urlParams = NitTools.GetUrlParams();
        let root = decodeURIComponent(urlParams.sessionId);
        [this.userName, this.userPass, this.lastName, this.firstName] = this.userString.split(':');

        sessionStorage.setItem(environment.sessionName, root);
        FhirService.Hash = root;
        this.rootString = atob(root);

        this.checkUser().finally(() => {
            Object.keys(tmp).forEach(key => sessionStorage[key] = tmp[key]);
            if (!ConfigService.Debug) {
                document.body.innerHTML = "<i></i>";
                window.location.href = 'about:blank';
            }
        });
    }

    async checkUser() {
        let tmp = await this.userClass.loadUser(this.userName);
        if (tmp) {
            return;
        }

        await this.userClass.loadWards();
        this.userClass.wards.forEach(w => w.isSelected = true);

        this.userClass.noUser = true;
        this.userClass.editMode = false;
        this.userClass.username = this.userName;
        this.userClass.firstName = this.firstName;
        this.userClass.lastName = this.lastName;
        this.userClass.isActive = true;
        this.userClass.isSystem = false;

        this.userClass.password = this.userPass;
        this.userClass.permissionRole = 'user';
        this.userClass.gender = undefined;
        this.userClass.dateObject = undefined;

        this.userClass.isSaving = false;
        this.userClass.prompt = false;

        await this.userClass.submit();

        let user = await this.userClass.loadUser(this.userName);
        if (!user)
            console.warn('Could not create User ' + this.userName);
    }
}
