import {TaskQueue, autoinject} from "aurelia-framework";
import {QuestionnaireService} from "../../../resources/services/QuestionnaireService";
import {RuntimeInfo} from "../../../resources/classes/RuntimeInfo";
import {FhirService} from "../../../resources/services/FhirService";
import {Questionnaire, QuestionnaireResponse} from "../../../resources/classes/FhirModules/Fhir";
import {I18N} from "aurelia-i18n";
import {ConfigService} from "../../../resources/services/ConfigService";

const moment = require("moment");

@autoinject
export class CsvExport {
    constructor(protected questionnaireService: QuestionnaireService, protected fhirService: FhirService, protected i18n: I18N, protected queue: TaskQueue) {
        if (ConfigService.Debug)
            window["csv"] = this;
    }

    inProgress: boolean = true;
    completed: boolean = true;
    enteredInError: boolean = false;
    includeEncounterId: boolean = false;
    questionnaireId: string;
    questionnaires: { id: string, name: string, title: string }[] = [];
    fromValue: Date;
    toValue: Date;
    includeQuestionnaireIds: boolean = false;
    includeOptionTexts: boolean = true;
    dpOptions = {
        format: RuntimeInfo.DateFormat
    };
    out: HTMLDivElement;
    currentProgress: number = 0;
    maxProgress: number = 0;
    percentProgress: number = 0;
    isLoading: boolean = false;
    questionnaire: any;

    debug(s: string) {
        this.out.innerText = s;
    }

    getUrl(forCount: boolean): string {
        const url = `QuestionnaireResponse?${this.getQueryParams(forCount).join('&')}`;
        return url.trim();
    }

    getQueryParams(forCount: boolean): string[] {
        const queryParams: string[] = [];
        let stati: string[] = [];
        if (this.inProgress) stati.push('in-progress');
        if (this.completed) {
            stati.push('completed');
            stati.push('amended');
        }
        if (this.enteredInError) {
            stati.push('entered-in-error');
            stati.push('stopped');
        }

        if (stati.length === 0) {
            stati.push('in-progress');
            stati.push('completed');
            stati.push('amended');
            this.inProgress = true;
            this.completed = true;
        }

        this.toValue.setHours(23);
        this.toValue.setMinutes(59);
        this.toValue.setSeconds(59);


        queryParams.push('status=' + stati.join(','));
        queryParams.push(`questionnaire=${this.questionnaireId}`);
        queryParams.push(`authored=ge${moment(this.fromValue).toJSON()}`);
        queryParams.push(`authored=le${moment(this.toValue).toJSON()}`);
        queryParams.push(`_include=QuestionnaireResponse:${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}`);

        if (!forCount) {
            queryParams.push(`_sort=-authored`);
            queryParams.push(`_count=250`);
        }

        return queryParams;
    }

    getOptionTexts(linkId: string, values: string[]): string[] {
        const result = values.slice(); // return the ids if no option is found
        const questionnaireItem = Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaire, linkId);

        if (questionnaireItem) {
            try {
                if (questionnaireItem.type === 'date' && values.length === 1 && values[0]) {
                    try {
                        let testDate = new Date(values[0]);
                        let dateString = moment(testDate).format(RuntimeInfo.DateFormat);
                        if (dateString) result[0] = dateString;
                    } catch (error) {
                        console.warn(error);
                    }
                } else if (questionnaireItem.type === "boolean") {
                    result[0] = this.i18n.tr(values[0] === 'true' ? 'yes' : 'no');
                } else if (questionnaireItem.option) {
                    for (let i = 0; i < values.length; i++) {
                        const value = values[i];
                        let sValue: string = undefined;
                        let option = questionnaireItem.option.find(o => o.valueCoding && o.valueCoding.code && o.valueCoding.code === value);
                        if (option) sValue = option.valueCoding.display || ' '; // option.valueCoding.code;
                        if (sValue) result[i] = sValue;
                    }
                }
            } catch (error) {
                console.warn(error);
            }
        }

        return result;
    }

    getOptionIds(response: any, linkId: string): string[] {
        const result = [];
        const responseItem = QuestionnaireResponse.GetResponseItemByLinkId(response, linkId, false);
        const questionnaireItem = Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaire, linkId);

        if (responseItem && responseItem.answer) {
            responseItem.answer.forEach(a => {
                let sValue = QuestionnaireResponse.GetItemAnswer(a, undefined);
                if (typeof sValue === "number") {
                    sValue = Number(sValue).toLocaleString();
                } else if (typeof sValue === "boolean") {
                    sValue = String(sValue); // this.i18n.tr(sValue ? 'yes' : 'no');
                } else {
                    sValue = String(sValue || '').trim();
                }

                if (sValue && (sValue.indexOf('|') === 0 || sValue.indexOf('=') === 0 || sValue.indexOf('grafixx_') === 0 || sValue.endsWith('_nil')))
                    sValue = '';

                if (sValue) result.push(sValue);
            });
        } else if (!responseItem && questionnaireItem) {
            if (questionnaireItem.type === 'boolean') result.push('false');
        }

        return result;
    }

    isItemValid(item: any): boolean {
        if (item && ['group', 'display', 'reference'].indexOf(item.type) === -1) {
            if (!item.extension) return true; // no extension is valid
            else {
                // if grafixx item it is not valid
                let gfx = item.extension.find(o => o && o.url && o.url.endsWith('grafixx_'));
                if (!gfx) gfx = item.extension.find(o => o && o.url && o.url.endsWith('response-list'));
                if (!gfx) gfx = item.extension.find(o => o && o.url && o.url.endsWith('questionnaire-hidden'));
                if (!gfx) gfx = item.extension.find(o => o && o.url && o.url.endsWith('questionnaire-supportLink'));

                return typeof gfx === "undefined";
            }
        } else {
            return false;
        }
    }

    updateProgress(bundle: any) {
        this.currentProgress += bundle.entry ? bundle.entry.filter(o => o.resource && o.resource.resourceType === 'QuestionnaireResponse').length : 0;
        this.debug(`loaded ${this.currentProgress} from ${this.maxProgress} Responses`);
        this.percentProgress = Math.round((this.currentProgress / this.maxProgress) * 100);
        if (this.percentProgress > 100) this.percentProgress = 100;
        else if (this.percentProgress < 0) this.percentProgress = 0;
    }

    xlsRows = [];

    async formSubmit() {
        this.xlsRows = [];
        this.currentProgress = 0;
        this.maxProgress = 0;
        this.debug('Counting Resources...');

        this.isLoading = true;
        if (!this.includeOptionTexts && !this.includeQuestionnaireIds) {
            this.includeOptionTexts = true;
        }

        this.maxProgress = await this.fhirService.count('QuestionnaireResponse', this.getQueryParams(true));
        this.debug('Got a total of ' + this.maxProgress);

        const resources = <any[]>await this.fhirService.fetch(this.getUrl(false), true, (bundle) => {
            if (this.maxProgress) this.updateProgress(bundle);
        });

        this.percentProgress = 100;
        this.debug('Converting data to CSV');

        const responses = <any[]>resources.filter(o => o.resourceType === 'QuestionnaireResponse');
        this.questionnaire = await this.questionnaireService.getQuestionnaireById(this.questionnaireId);
        const linkIds = Questionnaire.GetAllQuestionnaireItemLinkIds(this.questionnaire);

        let csvText = `"${this.i18n.tr('response_created')}";${this.includeEncounterId ? '"EncounterId";' : ''}"${this.i18n.tr('visitNumber')}";"${this.i18n.tr('status')}"`;
        let xlsHeaders = [
            this.i18n.tr('response_created'), this.includeEncounterId ? 'EncounterId' : '---',
            this.i18n.tr('visitNumber'), this.i18n.tr('status')
        ];

        xlsHeaders = xlsHeaders.filter(o => o && o !== '---');

        //#region Generate Headers
        linkIds.forEach(linkId => {
            try {
                let text: string = undefined;
                let fieldHeader: string = '?';
                let questionnaireItem = Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaire, linkId);
                if (this.isItemValid(questionnaireItem)) {
                    if (questionnaireItem && questionnaireItem.text) {
                        text = questionnaireItem.text;
                        if (text.length > 30) text = text.substr(0, 27) + '..';
                    }

                    if (this.includeOptionTexts && this.includeQuestionnaireIds) {
                        fieldHeader = `${text || '?'} (${linkId})`;
                    } else if (this.includeOptionTexts && !this.includeQuestionnaireIds) {
                        fieldHeader = text;
                    } else if (!this.includeOptionTexts && this.includeQuestionnaireIds) {
                        fieldHeader = linkId;
                    }

                    xlsHeaders.push(fieldHeader);
                    csvText += `;"${fieldHeader}"`;
                }
            } catch (e) {
                console.warn(e);
                csvText += `;"ERROR"`;
            }
        });

        this.xlsRows.push(xlsHeaders);
        csvText += '\n';
        //#endregion


        this.maxProgress = responses.length;
        this.currentProgress = 0;

        for (const response of responses) {
            await this.queue.queueMicroTask(() => {
                this.debug(`Converting record ${responses.indexOf(response) + 1}/${responses.length} to CSV`);
                this.currentProgress = responses.indexOf(response);
                this.percentProgress = Math.round((this.currentProgress / this.maxProgress) * 100);
            });

            let lineValues = [];
            const encounter = response && response.context && response.context.reference ? <any>resources.find(o => o.id === response.context.reference.split('/')[1]) : undefined;
            const encounterId = encounter ? encounter.id : '';
            let visitNumber = '';
            if (encounter) {
                if (encounter.identifier) {
                    const visitIdentifier = encounter.identifier.find(o => o.system?.indexOf('/visitNumber') > -1);
                    if (typeof visitIdentifier !== 'undefined' && visitIdentifier.value) {
                        const tmpVisitNumber = visitIdentifier.value.trim();
                        if (tmpVisitNumber !== 'undefined') visitNumber = tmpVisitNumber;
                    }
                }
            }

            lineValues.push(moment(response.authored).format(RuntimeInfo.DateTimeFormat));
            if (this.includeEncounterId) lineValues.push(encounterId);
            lineValues.push(visitNumber);
            lineValues.push(this.i18n.tr(response.status));

            linkIds.forEach(linkId => {
                let qItem = Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaire, linkId);
                if (this.isItemValid(qItem)) {
                    let saIdValues = this.getOptionIds(response, linkId);
                    let saTextValues = this.getOptionTexts(linkId, saIdValues);

                    if (saIdValues.length > 1) {
                        //#region Multiselect handling
                        let fieldValue = '';
                        if (this.includeQuestionnaireIds && this.includeOptionTexts && saTextValues.length === saIdValues.length) {
                            // add ids and options
                            let sa = [];
                            for (let i = 0; i < saIdValues.length; i++) {
                                sa.push(`${saTextValues[i]} (${saIdValues[i]})`);
                            }

                            fieldValue = sa.join(', ');
                        } else if (this.includeQuestionnaireIds && !this.includeOptionTexts) {
                            // only questionnaire IDs
                            fieldValue = saIdValues.join(', ');
                        } else if (!this.includeQuestionnaireIds && this.includeOptionTexts) {
                            // only text
                            fieldValue = saTextValues.join(', ');
                        } else {
                            console.warn("Something went wrong in CSV-Export");
                        }

                        lineValues.push(fieldValue);
                        //#endregion
                    } else if (saIdValues.length === 1) {
                        //#region single value handling
                        let sIdValue = String(saIdValues[0]);
                        let sTextValue = saTextValues[0];

                        let fieldValue = '';
                        if (this.includeOptionTexts && this.includeQuestionnaireIds) {
                            fieldValue = `${sTextValue} (${sIdValue})`;
                        } else if (!this.includeOptionTexts && this.includeQuestionnaireIds) {
                            fieldValue = sIdValue;
                        } else if (this.includeOptionTexts && !this.includeQuestionnaireIds) {
                            fieldValue = sTextValue;
                        }

                        let tst = String(fieldValue).trim();
                        if (tst.startsWith('<') && tst.endsWith('>')) {
                            let div = document.createElement('div');
                            div.innerHTML = tst;
                            fieldValue = div.innerText;

                            console.warn('HTML Detected: ', tst, "Converted to:", fieldValue);
                        }

                        fieldValue = fieldValue.replace(/"/g, '“');  // move " to \"
                        lineValues.push(fieldValue || '');
                        //#endregion
                    } else {
                        lineValues.push('');
                    }
                }
            });

            let xlsCols = [];
            for (let i = 0; i < lineValues.length; i++) {
                let l = lineValues[i];
                l = l.replace(/^"(.*)"$/, '$1'); // remove " from field for excel
                xlsCols.push(l);

                // ensure quoted values for csv
                if (l && l[0] && l[0] !== '"') {
                    lineValues[i] = `"${l}"`;
                }
            }

            this.xlsRows.push(xlsCols);

            csvText += lineValues.join(';') + '\n';
        }

        this.fileName = `${moment(this.fromValue).format('YYYY-MM-DD')}_to_${moment(this.toValue).format('YYYY-MM-DD')}`;
        this.fileName = this.questionnaire.title + '_' + this.fileName.replace(/ /g, '_').replace(/:/g, '_');
        this.isLoading = false;

        this.debug('Finished');
        this.csv = csvText;

        // store the max-length for each column in an array
        let maxLength = [];
        for (let col = 0; col < xlsHeaders.length; col++) {
            let max = 0;
            for (let row = 0; row < this.xlsRows.length; row++) {
                if (this.xlsRows[row]) {
                    let cell = this.xlsRows[row][col];
                    if (cell && cell.length > max) {
                        max = cell.length;
                    }
                }
            }

            maxLength[col] = max > 40 ? 40 : max;
        }
    }

    getTable(): HTMLTableElement {
        let table = document.createElement('table');
        let thead = document.createElement('thead');
        table.appendChild(thead);
        let trHead = document.createElement('tr');
        thead.appendChild(trHead);
        this.xlsRows[0].forEach(s => {
            let th = document.createElement('th');
            th.style.fontWeight = 'bold';
            th.innerText = s;
            trHead.appendChild(th);
        });

        let tbody = document.createElement('tbody');

        for (let i = 1; i < this.xlsRows.length; i++) {
            let tr = document.createElement('tr');
            for (const s of this.xlsRows[i]) {
                let td = document.createElement('td');
                td.innerText = s;
                tr.appendChild(td);
            }

            tbody.appendChild(tr);
        }

        table.appendChild(tbody);

        return table;
    }

    csv: string;
    fileName: string;

    downloadCSV() {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(this.csv));
        element.setAttribute('download', this.fileName + '.csv');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();
        document.body.removeChild(element);
    }

    download(type: string) {
        if (type === 'csv') return this.downloadCSV();
    }

    async attached() {
        this.isLoading = true;
        this.debug('Loading Questionnaires-List');

        await this.questionnaireService.fetch();
        this.questionnaireService.questionnaires.filter(o => o.status === 'active').forEach(q => this.questionnaires.push({
            id: q.id,
            name: q.name,
            title: q.title
        }));
        this.questionnaires.sort((a, b) => a.title.localeCompare(b.title));
        this.fromValue = new Date();
        this.fromValue.setDate(1);
        // this.fromValue.setFullYear(this.fromValue.getFullYear() - 2);
        this.toValue = new Date();

        this.debug(this.questionnaires.length + ' questionnaires loaded');
        this.isLoading = false;
    }
}
