export class ConfigItem {
    key: string;
    parent?: ConfigItem;
    children?: ConfigItem[] = [];
    _value: any = undefined;
    get value(): any {
        return this._value;
    }

    set value(newValue: any) {
        if (this.type === 'boolean' && newValue) {
            if (typeof newValue === "string") {
                this._value = newValue.toUpperCase() === "TRUE";
            } else this._value = !!newValue;
        } else {
            this._value = newValue;
        }
    }

    isGroup: boolean = false;
    _type: string;
    set type(newValue: string) {
        this._type = newValue;
        if (this._type === "object") {
            this.isGroup = true;
            if (!this.children) this.children = [];
        }
    }

    get type(): string {
        return this._type;
    }

    hint: string;

    toJS(): object {
        if (!this.isGroup) return this.value;

        let result = {};
        this.children.forEach((child: ConfigItem) => result[child.key] = child.toJS());
        return result;
    }

    constructor(item?, key?) {
        this.key = key;
        this.type = typeof item;
        if (typeof item === "undefined") {
            this.type = undefined;
            this.isGroup = false;
        }

        if (typeof item === "object") {
            this.children = [];
            this.isGroup = true;

            Object.keys(item).forEach((key: string) => {
                let child = new ConfigItem(item[key], key);
                child.parent = this;
                this.children.push(child);
            });
        } else {
            this.value = item;
        }
    }
}
