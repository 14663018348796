import {inject} from "aurelia-framework";
import {I18N} from "aurelia-i18n";
import * as Fhir from "../../../resources/classes/FhirModules/Fhir";
import {HttpClient, HttpResponseMessage} from "aurelia-http-client";
import {NitTools} from "../../../resources/classes/NursitTools";
import {LocationService} from "resources/services/LocationService";
import {DialogMessages} from "resources/services/DialogMessages";
import {AnalyzeService} from "resources/services/analyzeService";
import {fhirEnums} from "../../../resources/classes/fhir-enums";
import {FhirService} from "../../../resources/services/FhirService";
import {FhirModules} from "../../../resources/classes/FhirModules/FhirCompatibilityService";
import {Router} from "aurelia-router";
import FhirCompatibilityService = FhirModules.FhirCompatibilityService;
import ResourceType = fhirEnums.ResourceType;
import HTTPVerb = fhirEnums.HTTPVerb;
import {RuntimeInfo} from "../../../resources/classes/RuntimeInfo";
import {PatientService} from "../../../resources/services/PatientService";
import {ConfigService} from "../../../resources/services/ConfigService";
import {PatientItem} from "../../../resources/classes/Patient/PatientItem";

@inject(LocationService, I18N, AnalyzeService, DialogMessages, FhirCompatibilityService, Router, PatientService)
export class AdminTools {
    locationService: LocationService;
    analyzeService: AnalyzeService;
    wards: any[];
    i18n: I18N;
    dialogMessages: DialogMessages;
    fhirService: FhirService;
    fhirCompatibilityService: FhirCompatibilityService;
    checkingQuestionnaires: boolean = false;
    router: Router;
    patientService: PatientService;

    constructor(locationService: LocationService, i18n: I18N, analyzeService: AnalyzeService, dialogMessages: DialogMessages, fhirCompatibilityService: FhirCompatibilityService, r: Router, patientService: PatientService) {
        this.locationService = locationService;
        this.i18n = i18n;
        this.patientService = patientService;
        this.router = r;
        this.fhirCompatibilityService = fhirCompatibilityService;
        this.dialogMessages = dialogMessages;
        this.analyzeService = analyzeService;
        this.fhirService = new FhirService();

        this.pump_from = "http://161.97.139.36:8100";
        this.pump_to = "http://smilevb:8000";
        this.pump_from_user = "root";
        this.pump_from_pass = "id4admin";
        this.pump_to_user = "root";
        this.pump_to_pass = "M8tmirSpass!";
        this.anonymize = false;
    }

    flagsButton: HTMLButtonElement;

    async clearFlags() {
        let oldText = this.flagsButton.innerText;
        this.flagsButton.setAttribute("disabled", "disabled");
        this.recreateFlagsButton.setAttribute("disabled", "disabled");
        try {
            let flags = <any[]>await this.fhirService.fetch('Flag?identifier=marks');

            for (let i = 0; i < flags.length; i++) {
                this.flagsButton.innerText = `Deleting Flag ${i + 1}/${flags.length}`;
                try {
                    await this.fhirService.delete(flags[i]);
                } catch (e) {
                    console.warn("Error when deleting Flag \"Flag/" + flags[i].id + "\": " + (e.message || JSON.stringify(e)));
                }
            }

        } finally {
            this.flagsButton.innerText = oldText;
            this.flagsButton.removeAttribute("disabled");
            this.recreateFlagsButton.removeAttribute("disabled");
        }
    }

    recreateFlagsButton: HTMLButtonElement;
    createFlagsForWardId: string;

    async createFlagForSelectedWard() {
        this.flagsButton.setAttribute("disabled", "disabled");
        this.recreateFlagsButton.setAttribute("disabled", "disabled");
        // await this.reCreateFlagsForWard(this.createFlagsForWardId);
    }

    /* async reCreateFlagsForWard(wardId: string) {
        // ToDo: reCreateFlagsForWard in admin-tools
        let oldText = this.recreateFlagsButton.innerText;
        let qList = await QuestionnaireService.GetQuestionnaireIds();
        try {
            this.flagsButton.setAttribute("disabled", "disabled");
            this.recreateFlagsButton.setAttribute("disabled", "disabled");
            await this.locationService.fetch();
            let patients = await this.patientService.list(wardId);
            for (let i = 0; i < patients.length; i++) {
                let _pat = await this.patientService.load(patients[i].encounterId);
                let pli : any = new PatientListItem(_pat.flags, _pat, qList, _pat.riskAssessment);
                await this.analyzeService.analyse(_pat, undefined);
            }
        } catch (error) {
            console.warn(error)
        } finally {
            this.recreateFlagsButton.innerText = oldText;
            this.flagsButton.removeAttribute("disabled");
            this.recreateFlagsButton.removeAttribute("disabled");
        }
    } */

    async reCreateFlags() {
        let oldText = this.recreateFlagsButton.innerText;
        try {
            await this.locationService.fetch();
            for (let i = 0; i < this.locationService.wards.length; i++) {
                this.flagsButton.setAttribute("disabled", "disabled");
                this.recreateFlagsButton.setAttribute("disabled", "disabled");
                this.recreateFlagsButton.innerText = `Processing Ward ${i}/${this.locationService.wards.length} "${this.locationService.wards[i].name}"`;
                // this.reCreateFlagsForWard(this.locationService.wards[i].id);
            }
        } catch (error) {
            console.warn(error);
        } finally {
            this.recreateFlagsButton.innerText = oldText;
            this.flagsButton.removeAttribute("disabled");
            this.recreateFlagsButton.removeAttribute("disabled");
        }
    }

    checkQuestionnairesStatus: string;
    checkQuestionnaireResult: { id: string, warnings: string[], errors: string[], valid: boolean, display: string }[] = [];

    wardIdentifierButton: HTMLButtonElement;
    wardProgress: string = "";

    async setWardIdentifiers() {
        RuntimeInfo.IsLoading = true;
        this.wardIdentifierButton.setAttribute("disabled", "disabled");
        this.wardProgress = "Start loading Wards...";
        try {
            let bundle = <any>await this.fhirService.fetch("Location", false);
            let toUpdate = [];
            this.wardProgress = "Processing Wards...";
            bundle.entry.forEach(entry => {
                if (entry.resource) {
                    let loc = <any>entry.resource;
                    if (loc.name && loc.physicalType && loc.physicalType.coding && loc.physicalType.coding[0]) {
                        let pt = loc.physicalType.coding[0];
                        if (pt.code === "wa") {
                            if (!loc.identifier) {
                                loc.identifier = [];
                            }

                            if (typeof loc.identifier.find(o => o.system && o.system.endsWith("locationWard")) === "undefined") {
                                loc.identifier.push({
                                    use: "official",
                                    system: NitTools.ExcludeTrailingSlash(RuntimeInfo.SystemHeader) + "/locationWard",
                                    value: loc.name
                                });

                                toUpdate.push(loc);
                            }
                        }
                    }
                }
            });

            this.wardProgress = "Updating Wards on Fhir...";
            await this.fhirService.bundle(toUpdate, HTTPVerb.put);
            this.dialogMessages.prompt(`Updated ${toUpdate.length} Wards with Identifier`, "Info", false);
        } catch (error) {
            this.dialogMessages.prompt("Error when updating Wards in \"" + this.wardProgress + "\":<br />" + error, "Error", true);
        } finally {
            this.wardProgress = "";
            RuntimeInfo.IsLoading = false;
            this.wardIdentifierButton.removeAttribute("disabled");
        }
    }

    openDesigner(id: string) {
        this.router.navigate("designer/" + id);
    }

    async checkQuestionnaires() {
        this.checkQuestionnaireResult = [];
        this.checkQuestionnairesStatus = "Loading Questionnaires";
        let qs = <any[]>await this.fhirService.fetch("Questionnaire?_summary=true", true);
        this.checkQuestionnairesStatus = "Checking Questionnaires";
        for (let i = 0; i < qs.length; i++) {
            let q = qs[i];
            let result = {id: q.id, warnings: [], errors: [], valid: true, display: (q.title || q.name || q.id)};

            this.checkQuestionnairesStatus = "Checking Questionnaire " + result.display;
            if (!q.title) result.errors.push("No Title set");
            if (!q.name) result.errors.push("No Name set");
            if (!q.status) result.errors.push("No Status set");

            if (q.status && (q.status !== "draft" && q.status !== "active")) {
                result.warnings.push("Check status: " + q.status);
            }

            result.valid = result.errors.length === 0 && result.warnings.length === 0;
            if (!result.valid) this.checkQuestionnaireResult.push(result);
        }

        this.checkQuestionnairesStatus = "Finished";
    }

    async attached() {
        await this.locationService.fetch();
        this.wards = this.locationService.wards;
        this.wards.forEach(ward => this.dropDownOptions.push({key: ward.id, text: ward.name}));
        if (ConfigService.Debug) window["adminTools"] = this;
    }

    //#region misc functions
    nameIndex: number = 0;
    nameOffset: number = 0;
    loadingRandomData: boolean = false;

    getClient(server: string, user: string, pass: string): HttpClient {
        let client = new HttpClient();
        client.configure((x) => {
            x.withHeader('Accept', 'application/fhir+json');
            x.withHeader('Content-Type', 'application/fhir+json');
            x.withHeader('Authorization', `Basic ${btoa(user + ":" + pass)}`);
            x.withBaseUrl(server);
        });

        return client;
    }

    killStatus: string;
    expungeCount: number = 1000;

    private async expungeResourceType(type: string) {
        await this.fhirService.client.post(type + "/$expunge",
            {
                "resourceType": "Parameters",
                "parameter": [
                    {
                        "name": "limit",
                        "valueInteger": this.expungeCount
                    },
                    {
                        "name": "expungeDeletedResources",
                        "valueBoolean": true
                    }, {
                        "name": "expungePreviousVersions",
                        "valueBoolean": true
                    }
                    /* ,{
                        "name": "expungeEverything",
                        "valueBoolean": true
                    } */
                ]
            });
    }

    async killResourceType(resourceType: string) {
        let resourceCount = await this.fhirService.count(resourceType);
        let runCount = Math.round(resourceCount / this.expungeCount);
        for (let i = 0; i <= runCount; i++) {
            this.killStatus = `Expunging next ${this.expungeCount} ${resourceType}, Pass ${i + 1}/${runCount}`;
            await this.expungeResourceType(resourceType);
        }

        if (ConfigService.Debug)
            console.debug("There are now" + (await this.fhirService.count(resourceType)) + " Resources of type " + resourceType + " left");
    }

    async killResources(type: string) {
        let resources = <any[]>await this.fhirService.fetch(type, true);
        this.killStatus = "Killing slowly...";

        resources = resources.sort((r1, r2) => {
            if (r1["basedOn"] && !r2["basedOn"]) {
                return -11;
            } else if (!r1["basedOn"] && r2["basedOn"]) {
                return 1;
            }

            return 0;
        });

        for (const r of resources) {
            this.killStatus = `Killing ${type} ${resources.indexOf(r) + 1}/${resources.length}`;
            await this.fhirService.delete(r);
        }

        this.killStatus = "Expunge " + type + " again...";
        await this.expungeResourceType(type);
    }

    async killProcedureRequests() {
        this.killStatus = "Killing started";
        /* await this.killResourceType(ResourceType.procedure);
        await this.killResourceType(ResourceType.carePlan);
        await this.killResourceType(ResourceType.procedureRequest); */

        await this.killResources(ResourceType.procedure);
        await this.killResources(ResourceType.carePlan);
        await this.killResources(ResourceType.procedureRequest);


        this.killStatus = "Cleared";
    }

    async getRandomPatient(force: boolean = false) {
        // randomdata is generate by the api of randomuser.me
        if (!this.anonymize && !force) return;
        this.loadingRandomData = true;

        try {
            if (!this.randomUsers) {
                this.randomUsers = [];
                for (let p = 1; p <= 10; p++) {
                    let result = await new HttpClient().get(`config/random_data/person_${p}.json`);
                    let js = JSON.parse(result.response);
                    for (let i = 0; i < js.length; i++) {
                        this.randomUsers.push(js[i]);
                    }
                }
            }
        } catch (e) {
            console.warn(e.message);
            this.randomUsers = [];
        } finally {
            this.loadingRandomData = false;
        }
    }

    async fetch(url: string, client: HttpClient): Promise<any[]> {
        let result: any[] = [];
        let searchResponseBundleMessage = await client.get(url);
        let message = this.checkPumpBundleResult(searchResponseBundleMessage);
        if (message) {
            console.warn(message);
        }

        let bundle: any = JSON.parse(searchResponseBundleMessage.response);

        this.totalResources = bundle.total;
        if (!bundle.entry || bundle.total === 0) return Promise.resolve([]);
        if (bundle.total) {
            this.pumpFetchPagesTotal = Math.round(bundle.total / bundle.entry.length);
        }

        bundle.entry.forEach(entry => result.push(entry.resource));

        this.pumpOperation = "FETCHING...";

        let nextLink = bundle.link.find(o => o.relation == fhirEnums.LinkRelation.next);
        if (nextLink) {
            this.pumpFetchPageCurrent++;
            this.pumpOperation = `FETCHING next page (${this.pumpFetchPageCurrent}/${this.pumpFetchPagesTotal})`;
            let nextResult = await this.fetch(nextLink.url, client);
            nextResult.forEach(nr => {
                if (nr) result.push(nr);
            });
        }

        return Promise.resolve(result);
    }

    anonymizePatient(patient: any): any {
        let rName = this.randomUsers[this.nameIndex];
        let rName2 = this.randomUsers[this.nameIndex + this.nameOffset];
        if (!patient) return undefined;
        patient.gender = rName.gender === "female" ? fhirEnums.AdministrativeGender.female : fhirEnums.AdministrativeGender.male;

        patient.name = [
            {
                family: rName.name.last,
                given: [rName2.name.first]
            }
        ];
        patient.address = [
            {
                city: rName.location.city,
                use: fhirEnums.AddressUse.home,
                state: rName.location.state,
                postalCode: rName.location.postcode,
                country: "germany",
                line: [rName.location.street],
                type: fhirEnums.AddressType.both,
                text: `<b>${patient.gender === fhirEnums.AdministrativeGender.male ? 'Hr.' : 'Fr.'} ${rName.name.last} ${rName2.name.first}</b><br />${rName.location.street}<br />${rName.location.postcode} ${rName.location.city}<br />${rName.location.state}`
            }
        ];

        patient.telecom = [
            {system: fhirEnums.ContactPointSystem.phone, value: rName.phone, use: fhirEnums.ContactPointUse.home},
            {system: fhirEnums.ContactPointSystem.email, value: rName.email, use: fhirEnums.ContactPointUse.home},
            {system: fhirEnums.ContactPointSystem.sms, value: rName.cell, use: fhirEnums.ContactPointUse.mobile}
        ];

        patient.birthDate = new Date(rName.dob.date).toJSON();
        patient.identifier = [
            {
                system: "http://randomuser.me/random-id",
                value: rName.login.username
            }];


        this.nameIndex++;
        if (this.nameIndex >= this.randomUsers.length - 2) {
            this.nameIndex = 0;
            this.nameOffset++;
        }

        return patient;
    }

    //#endregion

    //#region anonymize
    anonymize_server: string = "http://192.168.178.24:8000";
    isAnonymizing: boolean = false;
    anonChunkSize: number = 250;
    anonTotalPatients: number = 0;
    anonCurrentPatient: number = 0;
    anonymize_user: string = "root";
    anonymize_pass: string = "id4admin";
    loadingPatients: boolean = false;
    expungingPatients: boolean = false;

    async anonymizePatients() {
        this.nameIndex = 0;
        this.nameOffset = 0;
        let oldHash = FhirService.Hash;
        let oldEndpoint = FhirService.Endpoint;

        FhirService.Endpoint = this.anonymize_server;
        FhirService.Hash = btoa(this.anonymize_user + ":" + this.anonymize_pass);

        try {
            let client = this.getClient(this.anonymize_server, this.anonymize_user, this.anonymize_pass);
            this.loadingRandomData = true;
            await this.getRandomPatient(true);
            this.loadingRandomData = false;
            this.loadingPatients = true;
            let patients = <any[]>await this.fhirService.fetch("Patient?_count=" + this.anonChunkSize);
            this.loadingPatients = false;
            this.isAnonymizing = true;
            this.anonTotalPatients = patients.length;
            for (let i = 0; i < patients.length; i++) {
                this.anonCurrentPatient = i;
                patients[i] = this.anonymizePatient(patients[i]);
                await this.fhirService.update(patients[i]);
            }

            this.expungingPatients = true;

            let resources = [fhirEnums.ResourceType.patient]; // this.getResourceTypes();
            for (let i = 0; i < resources.length; i++) {
                let url = `${resources[i]}/$expunge`;
                try {
                    await client.post(url, {
                        "resourceType": "Parameters",
                        "parameter": [
                            {
                                "name": "expungePreviousVersions",
                                "valueBoolean": true
                            },
                            {
                                "name": "expungeDeletedResources",
                                "valueBoolean": true
                            }
                        ]
                    });
                } catch (ex) {
                    console.warn(ex.response || ex.message || ex);
                }
            }
        } catch (ex) {
            console.warn(ex.response || ex.message || ex);
        } finally {
            FhirService.Endpoint = oldEndpoint;
            FhirService.Hash = oldHash;
            this.isAnonymizing = false;
            this.loadingPatients = false;
            this.expungingPatients = false;
        }
    }

    async globalExpungePatientHistory() {
        try {
            this.isAnonymizing = true;
            this.expungingPatients = true;

            let url = `${fhirEnums.ResourceType.patient}/$expunge`;
            let client = this.getClient(this.anonymize_server, this.anonymize_user, this.anonymize_pass);
            let countBundleResponse: HttpResponseMessage = await client.get("Patient?_summary=count");
            let js: any = JSON.parse(countBundleResponse.response);
            //let count : number;

            console.debug('Expunge Patient', await client.post(url, {
                "resourceType": "Parameters",
                "parameter": [
                    {
                        "name": "limit",
                        "valueInteger": js.total + 1
                    },
                    {
                        "name": "expungePreviousVersions",
                        "valueBoolean": true
                    },
                    {
                        "name": "expungeDeletedResources",
                        "valueBoolean": true
                    }
                ]
            }));

            url = `${fhirEnums.ResourceType.location}/$expunge`;
            countBundleResponse = await client.get("Location?_summary=count");
            js = JSON.parse(countBundleResponse.response);
            // count = js.total;

            console.debug('Expunge Locations:', await client.post(url, {
                "resourceType": "Parameters",
                "parameter": [
                    {
                        "name": "limit",
                        "valueInteger": js.total + 1
                    },
                    {
                        "name": "expungePreviousVersions",
                        "valueBoolean": true
                    },
                    {
                        "name": "expungeDeletedResources",
                        "valueBoolean": true
                    }
                ]
            }));


        } catch (ex) {
            console.warn(ex.response || ex.message || ex);
        } finally {
            this.isAnonymizing = false;
            this.expungingPatients = false;
        }
    }

    async expungeHistory(client: HttpClient, patientId: string) {
        // POST[base] / Patient / 123 / _history / 2 / $expunge
        // Content - Type: application / fhir + json
        let obj = {
            "resourceType": "Parameters",
            "parameter": [
                {
                    "name": "expungeEverything",
                    "valueBoolean": true
                },
                {
                    "name": "expungePreviousVersions",
                    "valueBoolean": true
                },
                {
                    "name": "expungeDeletedResources",
                    "valueBoolean": true
                }
            ]
        };

        let url = `${this.anonymize_server}/${fhirEnums.ResourceType.patient}/${patientId}/_history/$expunge`;
        return await client.post(url, obj);
    }


    //#endregion

    //#region DATA PUMP
    namesJson: string;
    pump_from: string;
    pump_to: string;
    pump_from_user: string;
    pump_from_pass: string;
    pump_to_user: string;
    pump_to_pass: string;
    pumping: boolean = false;
    currentPumpName: string;
    totalResources: number;
    currentPumpCurrent: number;
    pumpTotalResource: number;
    pumpCurrentResourceIndex: number;
    pumpOperation: string = "Not running";
    deleting: boolean = false;
    totalDeletePasses: number = 3;
    currentDeletePass: number = 0;
    chunkSize: number = 250;
    startTime: Date;
    endTime: Date;
    anonymize: boolean = true;
    pumpFetchPagesTotal: number = 0;
    pumpFetchPageCurrent: number = 0;
    pumpLog: string;

    compareResult: {
        source: number,
        target: number,
        name: string
    }[] = undefined;

    randomUsers: any[] = undefined;

    getResourceTypes(inverse: boolean = false): string[] {

        /*let en = fhirEnums;
        let nameKeys = [];*/

        let _keys = Object.values(fhirEnums.ResourceType).filter(o => ["Observation", "List", "SearchParameter", "Questionnaire", "QuestionnaireResponse", "Media",
            "Practitioner", "Patient", "Location", "Encounter", "DomainResource", "Resource", "Organization",
            "Bundle", "ConceptMap", "StructureDefinition", "StructureMap"].indexOf(o) === -1);

        // this should be the 1st resources to pump
        let keys = [/*"SearchParameter", */
            "Organization", "Location", "Practitioner", "Patient", "Encounter", "Questionnaire", "Observation", "QuestionnaireResponse", "Media"];
        _keys.forEach(k => keys.push(k));

        // lists at last please
        keys.push("List");

        if (inverse) keys = keys.reverse();

        return keys;
    }

    async compareAfterPump() {
        this.pumpOperation = "COMPARING";
        this.compareResult = [];
        let keys = this.getResourceTypes(false).sort();
        let clientSource = this.getClient(this.pump_from, this.pump_from_user, this.pump_from_pass);
        let clientTarget = this.getClient(this.pump_to, this.pump_to_user, this.pump_to_pass);
        this.pumpTotalResource = keys.length;
        this.currentPumpCurrent = 0;
        this.totalResources = 0;

        for (let i = 0; i < keys.length; i++) {
            let ele = {
                source: 0,
                target: 0,
                name: keys[i]
            };

            this.currentPumpName = keys[i];
            this.pumpCurrentResourceIndex = i;

            let countSource = await clientSource.get(keys[i] + "?_summary=count");
            let js = JSON.parse(countSource.response);
            ele.source = parseInt(String(js.total));

            let countTarget = await clientTarget.get(keys[i] + "?_summary=count");
            js = JSON.parse(countTarget.response);
            ele.target = parseInt(String(js.total));

            if (ele.source !== ele.target) {
                this.compareResult.push(ele);
            }
        }
    }

    async pumpResourceType(type: string, from: HttpClient, to: HttpClient): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                this.totalResources = 0;
                this.pumpFetchPageCurrent = 0;
                this.pumpFetchPagesTotal = 0;

                this.pumpOperation = "COPY Resources";
                this.currentPumpName = type;
                this.currentPumpCurrent = 0;
                this.totalResources = 0;

                let getUrl = type;
                this.pumpOperation = "FETCHING Resources";
                let resources = await this.fetch(getUrl + "?_count=" + this.chunkSize, from);
                if (!resources) {
                    resolve();
                    return;
                }

                this.totalResources = resources.length;
                if (resources.length === 0) {
                    resolve();
                    return;
                }

                if (type === "Patient" && this.anonymize) {
                    if (!this.randomUsers) {
                        this.pumpOperation = "Loading random person data";
                        await this.getRandomPatient();
                        if (!this.randomUsers || this.randomUsers.length === 0) {
                            reject("No Random Persondata loaded");
                            return;
                        }
                    }

                    this.nameOffset = 0;
                    this.nameIndex = 0;
                    for (let i = 0; i < resources.length; i++) {
                        resources[i] = this.anonymizePatient(<any>resources[i]);
                    }
                }

                if (resources.length > this.chunkSize) {
                    // push the locations all at once
                    // many many resources, so chunk them
                    let chunks = NitTools.Chunk(resources, this.chunkSize);
                    for (let c = 0; c < chunks.length; c++) {
                        this.pumpOperation = "PUT Resources-Chunk (" + (c + 1) + "/" + chunks.length + ")";
                        let bundle = Fhir.Tools.GetBundle(chunks[c], type == fhirEnums.ResourceType.searchParameter ? fhirEnums.HTTPVerb.post : fhirEnums.HTTPVerb.put, fhirEnums.BundleType.transaction);
                        this.currentPumpCurrent = c * this.chunkSize;
                        let resultBundleResponse = await to.post(this.pump_to, bundle)
                            .catch(e => {
                                let msg = JSON.stringify(e);
                                if (e.response) {
                                    msg = this.checkPumpBundleResult(<HttpResponseMessage>e);
                                    console.warn(msg);
                                } else {
                                    console.warn(e);
                                }

                                this.pumpLog = "Error: " + msg;
                            });

                        this.checkResponseBundle((<HttpResponseMessage>resultBundleResponse).response, this.currentPumpName);
                    }
                } else {
                    // less than 250 resources, just post the bunde
                    this.pumpOperation = "PUT Resources";
                    let bundle = Fhir.Tools.GetBundle(resources, type == fhirEnums.ResourceType.searchParameter ? fhirEnums.HTTPVerb.post : fhirEnums.HTTPVerb.put, fhirEnums.BundleType.transaction);
                    this.currentPumpCurrent = resources.length;
                    let resultBundleResponse: HttpResponseMessage = <HttpResponseMessage>await to.post(this.pump_to, bundle)
                        .catch(e => {
                            let msg = JSON.stringify(e);
                            if (e.response) {
                                msg = this.checkPumpBundleResult(<HttpResponseMessage>e);
                                console.warn(msg);
                            } else {
                                console.warn(e);
                            }

                            this.pumpLog = "Error: " + msg;
                        });
                    this.checkResponseBundle(resultBundleResponse.response, this.currentPumpName);
                }

                resolve();
            } catch (error) {
                reject(JSON.stringify(error));
            }
        });
    }

    checkPumpBundleResult(msg: HttpResponseMessage): string {
        let result: string = "";
        if (msg.response) {
            let response = JSON.parse(msg.response);
            if (response.issue) {
                let issues: any[] = response.issue;
                issues.forEach(issue => {
                    let s = issue.severity + ": " + issue.diagnostics;
                    // console.warn(s);
                    result += s + "\n";
                });
            }
        }

        return result.trim();
    }

    checkResponseBundle(bundleJS: string, resourceName: string) {
        let resultBundle: any = JSON.parse(bundleJS);
        if (resultBundle.entry) {
            resultBundle.entry.forEach(entry => {
                if (!entry.response.status.startsWith("20") || (entry.response.outcome && entry.response.outcome.issue)) {
                    let errorText = "";
                    if (entry.response.outcome.issue) {
                        entry.response.outcome.issue.forEach(issue => {
                            errorText += "- " + issue.severity + ": " + issue.diagnostics + "\n";
                        });
                    }

                    errorText = errorText.trim() + "\n";

                    console.warn("Error when writing bundle entry for \"" + resourceName + "\".\nStatus: " + entry.response.status + "\nOutCome: " + entry.response.outcome + "\n" +
                        (errorText || ""));
                }
            });
        }
    }

    async clearTargetData() {
        this.compareResult = undefined;
        this.deleting = true;
        try {
            /* for (let i = 0; i < this.totalDeletePasses; i++) {
                this.currentDeletePass = i;
                await this.clearAllTargetData();
            } */
            await (this.clearAllTargetData());
        } catch (err) {
            console.warn(err);
        } finally {
            this.deleting = false;
        }
    }

    async clearAllTargetData() {
        let clientTarget = this.getClient(this.pump_to, this.pump_to_user, this.pump_to_pass);
        let keys = this.getResourceTypes(true);
        this.pumpTotalResource = keys.length;

        for (let i = 0; i < keys.length; i++) {
            this.pumpCurrentResourceIndex = i;
            this.currentPumpName = keys[i];

            await clientTarget.post(this.pump_to + "/" + keys[i] + "/$expunge",
                {
                    "resourceType": "Parameters",
                    "parameter": [
                        {
                            "name": "expungeEverything",
                            "valueBoolean": true
                        }
                    ]
                })
                .catch(e => {
                    console.warn(e);
                });
        }
    }

    async startDataPump() {
        try {
            this.compareResult = undefined;
            this.pumping = true;

            let keys = this.getResourceTypes(false);
            this.pumpTotalResource = keys.length;
            let clientSource = this.getClient(this.pump_from, this.pump_from_user, this.pump_from_pass);
            let clientTarget = this.getClient(this.pump_to, this.pump_to_user, this.pump_to_pass);

            for (let i = 0; i < keys.length; i++) {
                try {
                    this.startTime = new Date();
                    this.pumpCurrentResourceIndex = i;
                    this.currentPumpName = keys[i];
                    await this.pumpResourceType(keys[i], clientSource, clientTarget)
                        .catch(e => console.warn(e));
                    this.endTime = new Date();

                    let seconds = new Date(this.endTime.valueOf() - this.startTime.valueOf()).valueOf() / 1000;
                    let minutes = seconds / 60;
                    if (minutes >= 1) {
                        seconds -= minutes * 60;
                    }

                    let s = "DONE Pumping type:" + this.currentPumpName + " took: " + (minutes >= 1 ? minutes + "m, " : "") + seconds + "s";
                    this.pumpLog = s;
                } catch (error) {
                    this.pumpLog = "ERROR Pumping type:" + this.currentPumpName + ": " + error.message || JSON.stringify(error);
                    console.warn(error.message || JSON.stringify(error));
                }
            }

            await this.compareAfterPump();
        } finally {
            this.pumping = false;
        }
    }

    //#endregion ///////////////// END DATA PUMP //////////////////////

    //#region Recalc
    abortCalc: boolean = false;
    recalcWard: string;
    dropDownOptions: any[] = [];
    recalcing: boolean = false;
    recalcStatus: string;
    wardName: string;
    includeFinished: boolean = false;

    async recalc(wardId: string) {
        this.recalcing = true;
        try {
            let wIdx = this.wards.findIndex(o => o.id === wardId);
            this.wardName = (wIdx > -1 ? this.wards[wIdx].name : 'unknown');

            this.recalcStatus = "Fetching Encounters";

            const encounters = await this.fhirService.fetch('Encounter?location=' + wardId + '&_summary=true&_count=250&status:not=finished');
            for (const encounter of encounters) {
                let p: PatientItem;
                try {
                    console.info(`Processing Encounter ${encounter.id}`);
                    p = await PatientItem.Load(encounter.id);
                    if (p)
                      await this.analyzeService.analyse(p, p.latestAssessment, true, true);
                    else
                        console.warn(`No Patient for Encounter-Id: ${encounter.id}`);
                } catch (e) {
                    console.warn(JSON.stringify(p?.display || p?.name) + ': ',  e.message || e.response || JSON.stringify(e));
                } finally {
                    this.recalcStatus = `Encounter ${encounters.indexOf(encounter)}/${encounters.length},<br /> Patient: ${p?.display}`;
                }
            }

            /* await this.fhirCompatibilityService.recalculateAllEncounters(wardId, this.analyzeService, this.includeFinished, (p) => {
                this.recalcStatus = `Encounter ${p.currentEncounter}/${p.totalEncounters},<br /> Patient: ${p.display}, <br />Assessment: ${p.encounterCurrentAssessment}/${p.encounterTotalAssessments}<br />Status: ${p.status}`;
            }); */

            if (this.abortCalc) {
                this.abortCalc = false;
                return false;
            } else {
                return true;
            }
        } catch (e) {
            console.warn(e.message || e);
            this.dialogMessages.prompt("An error occured:" + (e.message || JSON.stringify(e)), "Error", true);
            this.recalcStatus = "Error...";
            return false;
        } finally {
            this.recalcing = false;
        }
    }

    abortCalcClicked() {
        this.abortCalc = true;
        this.fhirCompatibilityService.abortRecalcAllEncounters();
    }

    async recalcRisks(all: boolean) {
        this.recalcStatus = "Starting...";
        if (!all) {
            if (await this.recalc(this.recalcWard))
                this.dialogMessages.prompt(`Ward "${this.wardName}" recalulated`, "Information", false);
        } else {
            let r: boolean = true;
            for (let i = 0; i < this.wards.length; i++) {
                if (this.abortCalc) {
                    this.fhirCompatibilityService.abortRecalcAllEncounters();
                    break;
                }

                let r2 = await this.recalc(this.wards[i].id);
                if (!r2) r = false;
            }

            this.dialogMessages.prompt("All wards recalulated" + (r ? "" : " with Errors."), "Information", false);
        }
    }

    //#endregion
}
