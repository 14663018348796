import {autoinject} from "aurelia-dependency-injection";
import {DialogController} from "aurelia-dialog";
import {NitTools} from "../../../resources/classes/NursitTools";
import {LocationService} from "../../../resources/services/LocationService";
import {ISmileUser} from "../interfaces/ISmileUser";
import {FhirService} from "../../../resources/services/FhirService";
import {User} from "./user";
import {RuntimeInfo} from "../../../resources/classes/RuntimeInfo";
import {SmileService} from "../../../resources/services/SmileService";
import {fhirEnums} from "../../../resources/classes/fhir-enums";
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;
import {ConfigService} from "../../../resources/services/ConfigService";

@autoinject

/*** Import a txt/csv file in the form:
 <pre>Personalnummer;Name;Vorname;Key-User (ja/nein);Berufsgruppe/Abteilung;Kommentar;Rolle/Domain</pre>

 Example csv:
 <pre>Personalnummer;Name;Vorname;Key-User (ja/nein);Berufsgruppe/Abteilung;Kommentar;Rolle/Domain
 121909;Schäfer-Kania;Daniela;;ärztlicher Dienst;;Arzt;</pre>
 */
export class UserCsvImport {
    private users: ICsvUser[] = []; // the list of users
    private locations: ICsvLocation[] = []; // the list of locations
    private curPage: number = 0; // the current page
    private pages = ['Imported Users', 'Locations for Users', 'Confirm Import']; // the page titles
    private needsLoadData: boolean = true; // indicator if a csv file has been loaded
    private defaultPass: string = RuntimeInfo.Features?.autoUserCreation?.defaultPassword || "Passwort123!"; // the default password to use
    private abortMessage: string; // message giving info why import has been aborted
    private importRunning: boolean = false; // indicates whether the import is currently running
    private fhirService: FhirService; // fhirservice used for creating resources
    private importMessage: string; // status message displayed while importing
    private importFinished : boolean = false; // is set to true when the import succeeded

    /*** initializes a new instance of the UserCsvImport class */
    constructor(protected controller: DialogController, protected smileService: SmileService) {
        this.fhirService = new FhirService();
    }

    private async startImport() {
        this.importRunning = true;
        this.importFinished = false;
        this.abortMessage = undefined;
        this.importMessage = 'Import started..';
        try {
            const roleLocations: any[] = [];
            for (const location of this.locations.filter(o => o.isSelected)) {
                roleLocations.push({
                    reference: `Location/${location.id}`,
                    display: location.name
                });
            }

            if (roleLocations.length === 0) throw "No Locations for users selected";

            const selectedUsers = this.users.filter(o => o.isValid && o.isSelected);
            if (selectedUsers.length === 0) throw "No Users selected";

            let idx = 0;
            const count = selectedUsers.length;
            for (const user of selectedUsers) {
                idx++;

                this.importMessage = `Creating user ${idx}/${count}`;

                let userData: ISmileUser = {
                    accountDisabled: false,
                    accountLocked: false,
                    external: false,
                    familyName: user.lastName,
                    givenName: user.firstName,
                    moduleId: FhirService.UserModuleId,
                    nodeId: FhirService.UserNodeId,
                    serviceAccount: user.role === 'admin',
                    systemUser: false,
                    username: user.personalNumber,
                    authorities: user.role === 'admin' ? [{permission: "ROLE_SUPERUSER"}] : User.DefaultUserAuthorities,
                    notes: user.comment,
                    password: this.defaultPass
                };

                await this.smileService
                    .createUser(userData)
                    .then(async result => {
                        if (result.error) {
                            throw result.error.field + ": " + result.error.message;
                        }

                        userData = <ISmileUser>result;

                        const prac = {
                            resourceType: "Practitioner",
                            id: NitTools.Uid(),
                            active: true,
                            name: [
                                {
                                    given: [user.firstName],
                                    family: user.lastName,
                                    use: 'official'
                                }
                            ],
                            identifier: [
                                {
                                    "system": `${RuntimeInfo.SystemHeader}/smile-account-id`,
                                    "value": user.personalNumber.toUpperCase()
                                },
                                {
                                    system: `${RuntimeInfo.SystemHeader}/smile-user-pid`,
                                    value: userData.pid,
                                },
                                {
                                    "system": `${RuntimeInfo.SystemHeader}/smile-user-role`,
                                    "value": user.role
                                }
                            ]
                        }

                        const role = {
                            resourceType: 'PractitionerRole',
                            id: NitTools.Uid(),
                            active: true,
                            extension: [{
                                url: "http://nursit-institute/authorization/needsPasswordChange",
                                valueBoolean: true
                            }],
                            practitioner: {
                                reference: `Practitioner/${prac.id}`
                            },
                            location: roleLocations
                        }

                        const fhirResult = await this.fhirService.bundle([prac, role], HTTPVerb.put, BundleType.transaction, false);
                        if (ConfigService.Debug)
                            console.log("Imported:", userData, fhirResult?.entry?.map(o=>o.resource));
                    })
            }

            this.importMessage = this.abortMessage = `Import Done. Processed ${count} users`;
            this.importFinished = true;
        } catch (e) {
            console.warn(e);

            this.importMessage = this.abortMessage = e;
            this.importRunning = false;
            this.importFinished = false;
        }
    }

    /*** gets a line from the csv file and returns undefined if not parseable or an instance of type ICsvUser */
    private parseLine(line: string): ICsvUser {
        if (!line || !line.trim() || line.indexOf(';') === -1 || line.indexOf('Personalnummer;Name;') === 0 || line.indexOf(';;;;') === 0) {
            return undefined;
        }

        let result: ICsvUser;
        try {
            const [personalNumber, lastName, firstName, keyUser, department, comment, role] = line.split(';');
            result = {
                personalNumber: personalNumber,
                lastName: lastName,
                firstName: firstName,
                isKeyUser: NitTools.ParseBool(keyUser),
                department: department,
                comment: comment,
                role: role,
                isSelected: false,
                _uid: NitTools.UidName(),
                isValid: true
            }

            if (!result.personalNumber && result.comment?.trim().indexOf('Nutzername') > -1) {
                result.personalNumber = result.comment.trim().replace('Nutzername', '').replace(/:/g, '').replace(/=/g, '').replace(/ /g, '').trim();
            }

            if (!result.personalNumber) throw "no personal number";
            if (result.personalNumber.toUpperCase() === "EXTERN") throw '"Extern" is not a valid value';
            if (!result.lastName) throw "No last name";
            if (!result.firstName) throw "No first name";
            if (!result.role) {
                result.role = 'nurse';
            }

            switch (result.role.toUpperCase()) {
                case 'ADMIN':
                    result.role = 'admin';
                    break;
                case "ARZT":
                    result.role = 'doctor';
                    break;
                case "PATIENTENMANAGEMENT":
                case 'PFLEGE':
                    result.role = 'nurse';
                    break;
                case 'AZUBI':
                    result.role = 'trainee';
                    break;
                case 'AUSZUBILDENDER':
                    result.role = 'trainee';
                    break;
                case 'CONTROLLING':
                    result.role = 'controlling';
                    break;
                case 'THERAPIE':
                    result.role = 'theraphy';
                    break;
                case 'APOTHEKE':
                    result.role = 'pharmacy';
                    break;
                case 'SOZIALDIENST':
                    result.role = 'socialservice';
                    break;
            }

            if (['socialservice', 'pharmacy', 'theraphy', 'controlling', 'trainee', 'nurse', 'doctor', 'admin'].indexOf(result.role) == -1) {
                throw `Role "${result.role}" not mapped`;
            }
        } catch (e) {
            console.warn(`Could not parse CSV-Line (${e}):\n${line}`);
            result.isValid = false;
            result.message = e;
        }

        return result;
    }

    /*** toggles the isSelected state for all elements on a page and sets it to the "selected" param */
    setSelected(selected: boolean) {
        switch (this.curPage) {
            case 0:
                for (const user of this.users) {
                    if (!user.isValid) continue;
                    user.isSelected = selected;
                }
                break;

            case 1:
                for (const location of this.locations) {
                    location.isSelected = selected;
                }
                break;
            default:
                break;
        }
    }

    /*** creates the file upload input and inserts it into the page */
    createUploadControl() {
        const file = document.createElement('input');
        file.type = 'file';
        const that = this;
        file.onchange = async (event) => {
            if (!file.value || !file.files[0]) return;

            this.users = [];
            try {
                const selectedFile = file.files[0];
                const fileType = selectedFile.type;

                if (fileType !== 'text/csv') {
                    alert('Please select a CSV file.');
                    return;
                }

                // let lines = await selectedFile.text();
                const decoder = new TextDecoder('utf-8'); // Adjust the encoding as needed
                const lines = decoder.decode(await file.files[0].arrayBuffer());

                const tmp: ICsvUser[] = [];
                if (lines) {
                    // lines = lines.replace(/\r?\n/g, '\n');
                    for (const line of lines.split('\n').filter(o => o)) {
                        const item = this.parseLine(line.trim());
                        if (!item) continue;

                        // make the stuff unique
                        if (typeof tmp.find(o=>o.personalNumber == item.personalNumber && o.isValid) != "undefined") {
                            item.isValid = false;
                            item.message = `Duplicate key "${item.personalNumber}"`;
                        }

                        tmp.push(item);
                    }

                    that.users = tmp.sort((a, b) => {
                        if (a.isValid && !b.isValid) return 1;
                        else if (!a.isValid && b.isValid) return -1;
                        else if (a.isValid && b.isValid) {
                            return a.personalNumber.localeCompare(b.personalNumber);
                        }
                    });

                    that.needsLoadData = that.users.length === 0;
                    if (!that.needsLoadData)
                        that.pages[0] += ` (${that.users.length})`;
                }
            } catch (e) {
                alert(`Error when importing:\n${e}`);
            } finally {
                file.value = '';
            }
        }

        document.querySelector('#csvupload').appendChild(file);
    }

    attached() {
        this.createUploadControl();

        this.locations = this.locations = LocationService.__staticLocationsList
            .filter(o => o.physicalType?.coding?.[0]?.code === 'wa' && o.name)
            .map(o => {
                return {id: o.id, name: o.name, isSelected: false, _uid: NitTools.UidName()}
            })
            .sort((a, b) => a.name.localeCompare(b.name));

        window["csv"] = this;
    }
}

export interface ICsvUser {
    personalNumber: string;
    lastName: string;
    firstName: string;
    isKeyUser?: boolean;
    department?: string;
    comment?: string;
    role?: string;
    isSelected: boolean;
    message?: string;
    _uid: string;
    isValid: boolean;
}

export interface ICsvLocation {
    id: string;
    name: string;
    isSelected: boolean;
    _uid: string;
}
