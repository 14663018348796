import {RuntimeInfo} from "../../../resources/classes/RuntimeInfo";
import {HttpClient} from "aurelia-http-client";
import {ConfigService} from "../../../resources/services/ConfigService";

export class Configurator {
    configFile: string = undefined;
    configSettings: any = undefined;
    formHolder: HTMLDivElement;

    constructor() {
        this.configFile = ConfigService.ConfigName;
    }

    async attached() {
        await this.loadConfig();
    }

    createFormElement = function (parentObject, obj, key, body, nameOfArray?) {
        if (key.indexOf("$") == 0) return;
        if (typeof parentObject[key] == "object") {
            let isArray = parentObject[key].constructor.toString().indexOf("Array") > -1;
            let keys = Object.keys(parentObject[key]);
            let group = document.createElement("div");
            group.classList.add("panel", "panel-default");
            let h5 = document.createElement("h5");
            h5.innerText = key;
            if (isArray) h5.innerText += " []";
            if (nameOfArray) h5.innerText = nameOfArray + "[" + key + "]";
            let cardBody = document.createElement("div");
            cardBody.classList.add("panel-body");
            cardBody.id = (body.id + "_" + key).replace("__", "_");
            cardBody.classList.add('collapse'); // 'in'

            if (h5.innerText.indexOf("[undefined]") > -1) h5.innerText = key; // h5.innerText.replace(/\[undefined\]/gi, "");
            if (obj["$IS_INFO"]) {
                h5.innerText = "(Info Element)";
                cardBody.classList.add('bg-info');
            }

            let hdr = document.createElement('div');
            hdr.classList.add('panel-heading', 'collapsed');
            hdr.setAttribute('data-toggle', 'collapse');
            hdr.setAttribute('aria-expanded', 'false');
            hdr.setAttribute('data-target', '#' + cardBody.id);

            hdr.appendChild(h5);

            group.appendChild(hdr);
            group.appendChild(cardBody);

            body.appendChild(group);
            const _that = this;
            for (let i = 0; i < keys.length; i++) {
                let arrayName = isArray ? key : undefined;
                if (nameOfArray) arrayName = nameOfArray + "[" + arrayName + "]";
                _that.createFormElement(parentObject[key], parentObject[key][keys[i]], keys[i], cardBody, arrayName);
            }
        } else {
            //let isArray = NitTools.IsArray(parentObject[key]);
            let group = document.createElement("div");
            group.classList.add("form-group");


            let label = document.createElement("label");
            label.classList.add("control-label");
            label.innerText = key + "(" + (typeof obj) + ")";

            let inputId = body.id + "_" + key;
            label.htmlFor = inputId;
            if (typeof obj !== "boolean") {
                body.appendChild(label);
            }

            let info = parentObject["$INFO_" + key];
            if (info) {
                let infoSpan = document.createElement("div");
                infoSpan.classList.add("text-info");
                infoSpan.innerText = info;
                body.appendChild(infoSpan);
            }

            let values = parentObject["$VALUES_" + key];
            let input;
            if (values) {
                let arr = values.split('|');
                input = document.createElement("select");

                for (let i = 0; i < arr.length; i++) {
                    let option = document.createElement("option");
                    option.value = arr[i];
                    option.text = arr[i];

                    if (parentObject[key] === option.value) option.selected = true;

                    input.appendChild(option);
                }

                input.value = JSON.stringify(parentObject[key]);
                input.classList.add("form-control");
                input.id = inputId;
            } else if (typeof parentObject[key] === "boolean") {
                input = document.createElement('div');
                input.classList.add('control-group');

                let cb = document.createElement("input");
                cb.type = "checkbox";
                cb.checked = String(parentObject[key]).toUpperCase() === "TRUE"; // ? "checked" : undefined;
                cb.id = inputId;
                input.appendChild(cb);

                let lbl = document.createElement('label');
                lbl.setAttribute('for', cb.id);
                lbl.innerText = key;
                input.appendChild(lbl);

            } else {
                input = document.createElement("input");
                input.type = "text";
                let str = JSON.stringify(parentObject[key]);
                str = str.replace(/^"(.*)"$/, '$1');
                input.value = str;
                input.classList.add("form-control");
                input.id = inputId;
            }

            $(input).data(key, parentObject[key]);

            group.appendChild(input);

            body.appendChild(group);
        }
    };

    createForm() {
        this.formHolder.innerHTML = '';
        let form = document.createElement("form");
        form.name = "jsForm";
        form.id = "jsForm";

        let card = document.createElement("div");
        card.classList.add("panel", "panel-default");
        let body = document.createElement("div");
        body.classList.add("panel-body");
        body.id = "root";

        let header = document.createElement('div');
        header.innerText = 'ROOT';
        header.classList.add('panel-heading');
        card.appendChild(header);

        card.appendChild(body);
        form.appendChild(card);

        let keys = Object.keys(this.configSettings);
        for (let i = 0; i < keys.length; i++) {
            this.createFormElement(this.configSettings, this.configSettings[keys[i]], keys[i], body);
        }

        this.formHolder.appendChild(form);
    }

    async loadConfig() {
        RuntimeInfo.IsLoading = true;
        let responseMessage = await new HttpClient().get(this.configFile);
        this.configSettings = JSON.parse(responseMessage.response);
        this.createForm();
        RuntimeInfo.IsLoading = false;
    }
}
