import { autoinject } from "aurelia-dependency-injection";
import { DialogController } from "aurelia-dialog";

@autoinject
export class MoveRole {
    private params : any;

    constructor(protected controller: DialogController) {

    }

    activate(params) {
        this.params = params;
    }
}
