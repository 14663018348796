import {autoinject} from "aurelia-framework";
import {FhirService} from "../../../../resources/services/FhirService";
import {fhirEnums} from "../../../../resources/classes/fhir-enums";
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;

@autoinject
export class BundleUpload {
    file: HTMLInputElement;

    constructor(protected fhirService: FhirService) {
    }

    readFile(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.addEventListener('load', (event) => {
                    const result = String(event.target.result);
                    resolve(result);
                });

                reader.readAsText(this.file.files[0]);
            } catch (err) {
                reject(err.message || err);
            }
        });
    }

    async uploadBundle() {
        let bundle = <any>JSON.parse(await this.readFile());
        let items = bundle.entry.filter(o => o.resource).map(o => o.resource);
        let resultBundle = await this.fhirService.bundle(items, HTTPVerb.put, BundleType.transaction, false);
        resultBundle.entry.forEach(entry => {
            if (!entry.response.status.startsWith('20')) {
                console.warn('Error putting resource:', entry);
            }
        });
    }
}
