import {FhirService} from "../../../../resources/services/FhirService";
import {fhirEnums} from "../../../../resources/classes/fhir-enums";
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;

export class EncounterPump {
    public sourceServer: string = "http://167.86.108.95:8030";
    public targetServer: string = "http://192.168.0.18:8030";

    public sourceUser: string = "root";
    public targetUser: string = "root";
    public sourcePass: string = "id4admin";
    public targetPass: string = "id4admin";

    public status: string;

    sleep(milliseconds) {
        const date = Date.now();
        let currentDate = null;
        do {
            currentDate = Date.now();
        } while (currentDate - date < milliseconds);
    }

    async startCopy() {
        this.status = "Loading Encounters...";
        const fsSource = new FhirService(this.sourceServer, btoa(`${this.sourceUser}:${this.sourcePass}`));
        //const fsTarget = new FhirService(this.targetServer, btoa(`${this.targetUser}:${this.targetPass}`));

        const count = await fsSource.count('Encounter');
        const maxPages = Math.round(count / 250);
        let curPage = 0;
        const ids = (<any[]>(await fsSource.fetch('Encounter?_summary=true&_count=250', true, () => {
            curPage++;
            this.status = "Getting Page " + curPage + "/" + maxPages;
        }))).map(o => o.id);

        const _fsTarget = new FhirService(this.targetServer, btoa(`${this.targetUser}:${this.targetPass}`));
        for (const id of ids) {
            const idx = id + " (" + ids.indexOf(id) + "/" + ids.length + ")";
            this.status = `Getting Encounter #${idx} from ${this.sourceServer}`;
            const entries = await fsSource.fetch(`Encounter/${id}/$everything?_count=250`, true);
            this.status = `Writing Encounter #${idx} to ${this.targetServer}`;
            await _fsTarget.bundle(entries, HTTPVerb.put, BundleType.transaction, false);

            this.sleep(1000);
        }
    }
}
