import {autoinject} from "aurelia-framework";
import {I18N} from "aurelia-i18n";
import {NitTools} from "resources/classes/NursitTools";
import {RuntimeInfo} from "resources/classes/RuntimeInfo";
import {ConfigService} from "resources/services/ConfigService";
import {DialogMessages} from "resources/services/DialogMessages";
import {FhirService} from "resources/services/FhirService";
import {PatientService} from "resources/services/PatientService";
import {QuestionnaireService} from "resources/services/QuestionnaireService";
import {UserService} from "resources/services/UserService";

const moment = require("moment");

@autoinject
export class ReprintReport {
    questionnaires: any[];
    counting: boolean = false;
    dateObjectStart: Date;
    dateObjectEnd: Date = new Date();
    statusHtml: string = '<i style="opacity: 0.8">- nothing running currently -</i>';
    totalReportsToPrint: number = undefined;
    generatedReportCount: number = undefined;
    generating: boolean = false;
    generationStartedTime: Date = undefined;
    percentDone: number = 0;
    percentDoneStr: string = undefined;
    doOverrideName: boolean = false;
    overrideFamilyName: string = 'auto';
    overrideGivenName: string = 'export';
    overrideUserId: string = 'tools';
    abort: boolean = false;
    aborting: boolean = false;
    failData: any[] = [];

    dpOptions = {
        format: RuntimeInfo.DateFormat
    };

    get generationDuration() {
        if (!this.generating) return -1;
        let now = new Date().valueOf();
        let diff = now - this.generationStartedTime.valueOf();
        return Math.round(diff / 1000);
    }

    reportGenerationAverageDuration: number = -1;

    get totalExptedDuration() {
        return Math.round(this.reportGenerationAverageDuration * this.totalReportsToPrint);
    }

    get estimatedRemainingDuration(): string {
        if (!this.generating) return '';
        let reportsToGo = this.totalReportsToPrint - this.generatedReportCount;
        const result = this.totalReportsToPrint === this.generatedReportCount ? 0 : Math.round(reportsToGo * this.reportGenerationAverageDuration);
        return this.secondsToHms(result);
    }

    secondsToHms(d) {
        d = Number(d);

        const h = Math.floor(d / 3600);
        const m = Math.floor(d % 3600 / 60);
        const s = Math.floor(d % 3600 % 60);

        if (isNaN(h) || isNaN(m) || isNaN(s)) return '-';
        if (h < 0 || m < 0 || s < 0) return '-';

        const result = ('0' + h).slice(-2) + ":" + ('0' + m).slice(-2) + ":" + ('0' + s).slice(-2);
        // if (result === 'ty:aN:aN') return '';
        return result;
    }

    isValid(value): boolean {
        if (!this.doOverrideName) return true;
        if (typeof value === 'undefined') return false;
        const sVal = String(value).trim();
        return sVal !== '';
    }


    toggleState(questionnaire) {
        if (this.generating) return;
        questionnaire.selected = !questionnaire.selected;
        if (!questionnaire.selected) {
            questionnaire.reports.forEach(report => {
                report.selected = false;
            });
        }
    }

    generateParams(questionnaire) {
        questionnaire.params = ['status=completed,amended',
            `questionnaire=${questionnaire.id}`,
            `authored=ge${moment(this.dateObjectStart).format('YYYY-MM-DD')}`,
            `authored=le${moment(this.dateObjectEnd).format('YYYY-MM-DD')}`];
        questionnaire.url = `QuestionnaireResponse?` + questionnaire.params.join('&') + '&_summary=true';
    }

    abortGeneration() {
        this.aborting = true;
        this.abort = true;
    }

    overrideReportGenerationData(r, practitioner) {
        const oGiven = String(this.overrideGivenName).trim();
        const oFamily = String(this.overrideFamilyName).trim();
        const oUserId = String(this.overrideUserId).trim();

        if (this.doOverrideName && oGiven && oFamily && oUserId) {
            r.userFirstName = oGiven;
            r.userLastName = oFamily;
            r.userName = oUserId;
        } else {
            if (practitioner && practitioner.name && practitioner.name.length > 0) {
                r.userFirstName = practitioner.name[0].family;
                r.userLastName = practitioner.name[0].given?.join(', ');
                if (!practitioner.identifier) practitioner.identifier = [];
                let userIdent = practitioner.identifier.find(o => o.system.endsWith('smile-account-id'));
                if (userIdent && userIdent.value) r.userName = userIdent.value;
            }
        }

        return r;
    }

    openCIW(fail) {
        let params = NitTools.GetUrlParams();
        if (!params.sessionId) {
            params.sessionId = FhirService.EmbeddedUserHash;
        }

        params.hideList = '1';
        params.embedded = '1';

        const paramsArray = [];
        Object.keys(params).forEach(key => paramsArray.push(`${key}=${String(params[key])}`));
        const urlParams = paramsArray.join('&');
        const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${urlParams}/#/encounter/${fail.encounterId}`;
        window.open(url, 'CIW', 'incognito=true,location=no,menubar=no,toolbar=no');
    }

    async getCounts() {
        if (this.generating || this.counting) return;
        this.counting = true;
        try {
            for (let i = 0; i < this.questionnaires.length; i++) {
                let q = this.questionnaires[i];
                if (q.selected) {
                    this.generateParams(q);
                    q.count = await this.fhirService.count('QuestionnaireResponse', q.params);
                }
            }
        } catch (err) {
            console.warn(JSON.stringify(err));
            this.dialogMessages.prompt(JSON.stringify(err), 'Error', true);
        } finally {
            this.counting = false;
        }
    }

    async attached() {
        this.questionnaires = (await QuestionnaireService.Fetch(true)).map(q => {
            return {
                id: q.id,
                title: q.title || q.name,
                selected: false,
                name: q.name,
                count: undefined,
                report: undefined,
                reports: [],
                params: [],
                url: 'QuestionnaireResponse',
                responses: []
            };
        });

        this.questionnaires.sort((a, b) => String(a.title).toUpperCase().localeCompare(String(b.title).toUpperCase()));

        this.questionnaires.forEach(q => {
            let setting = ConfigService.FormSettings.find(o => String(o.questionnaireName).toUpperCase() === String(q.name).toUpperCase());
            if (setting && setting.report) {
                let name = String(setting.report.name);
                if (name) {
                    if (name.toUpperCase().endsWith('.FRX')) {
                        name = name.substr(0, name.length - 4);
                    }

                    q.reports.push({report: name, selected: false, uid: NitTools.UidName()});
                    if (setting.report.progressSuffix) q.reports.push({
                        report: name + setting.report.progressSuffix,
                        selected: false,
                        uid: NitTools.UidName()
                    });
                }
            }
        });
    }

    constructor(protected i18n: I18N, protected fhirService: FhirService, protected dialogMessages: DialogMessages, protected patientService: PatientService, protected userService: UserService) {
        this.dateObjectStart = moment(new Date()).subtract(ConfigService.Debug ? 12 : 6, 'month').toDate();
        this.dateObjectStart = new Date(this.dateObjectStart.setDate(1));
    }
}
