import {inject, PLATFORM} from "aurelia-framework";

import {I18N} from "aurelia-i18n";
import {ConfigService} from "../../resources/services/ConfigService";
import {UserService} from "../../resources/services/UserService";
import {ReportService} from "resources/services/ReportService";
import {ClearLayout} from "../layouts/clear";

@inject(UserService, I18N)
export class careitAdmin {
    router;
    userService: UserService;
    i18n;

    constructor(userService, i18n) {
        this.userService = userService;
        this.i18n = i18n;
        this.isAjax = window.location.href.indexOf('/ensureUser/') > -1;
    }

    logOut() {
        if (this.userService.noLogin) {
            return;
        }

        this.userService.logout(true);
    }

    async attached() {
        await ConfigService.SetupI18n(this.i18n);
    }

    public get infoUrl(): string {
        return ReportService.BaseUrl ? ReportService.BaseUrl + '/Info' : undefined;
    }

    isAjax: boolean = false;

    configureRouter(config, router) {
        this.router = router;
        config.map([
            {
                route: ['index', ''],
                name: 'index',
                moduleId: PLATFORM.moduleName('views/admin/default'),
                nav: true,
                title: 'Start',
            },
            {
                route: 'icd',
                name: 'icd',
                moduleId: PLATFORM.moduleName('views/admin/icd/default'),
                nav: false,
                title: 'ICD-Mappings',
            },
            {
                route: 'report-generation',
                name: 'report-generation',
                moduleId: PLATFORM.moduleName('views/admin/report-generation/index'),
                nav: true,
                title: 'Report-Generation',
            },
            {
                route: 'csv-export',
                name: 'csv-export',
                moduleId: PLATFORM.moduleName('views/admin/csv-export/index'),
                nav: true,
                title: 'CSV-Export',
            },
            {
                route: 'location-editor',
                name: 'location-editor',
                moduleId: PLATFORM.moduleName('views/admin/location-editor/index'),
                nav: true,
                title: 'Location Editor',
            },
            {
                route: 'users',
                name: 'users',
                moduleId: PLATFORM.moduleName('views/admin/users/user-list'),
                nav: true,
                title: 'Users'
            },
            {
                route: 'user/:user?',
                name: 'user',
                moduleId: PLATFORM.moduleName('views/admin/users/user'),
                nav: false,
                title: 'User'
            },
            {
                route: 'user/ensureUser/:userString',
                name: 'ensureUser',
                moduleId: PLATFORM.moduleName('views/admin/users/createWithAjaxCall'),
                nav: false,
                layoutViewModel: ClearLayout,
                title: 'Ensure User'
            },
            /*{
                route: ['designer', 'designer/:id?'],
                name: 'designer',
                moduleId: PLATFORM.moduleName('views/admin/designer/designer-main'),
                nav: true,
                title: 'Designer',
                settings: {
                    underConstruction: true
                }
            }, */
            {
                route: 'config',
                name: 'config',
//                moduleId: PLATFORM.moduleName('views/admin/config/config-main'),
                moduleId: PLATFORM.moduleName('views/admin/config/index'),
                nav: true,
                title: 'Configuration',
                settings: {
                    underConstruction: true
                }
            },
            {
                route: 'tools',
                name: 'tools',
                nav: true,
                moduleId: PLATFORM.moduleName('views/admin/tools/admin-tools'),
                title: 'Tools',
                settings: {
                    underConstruction: true
                }
            }
        ]);
    }
}
