import {autoinject} from "aurelia-framework";
import {FhirService} from "../../../../resources/services/FhirService";
import {DialogMessages} from "../../../../resources/services/DialogMessages";
import {NitTools} from "../../../../resources/classes/NursitTools";
import {fhirEnums} from "../../../../resources/classes/fhir-enums";
import {RuntimeInfo} from "../../../../resources/classes/RuntimeInfo";
import {ConfigService} from "../../../../resources/services/ConfigService";

@autoinject
export class ImageAssign {
    fhirService: FhirService;
    loading: boolean = false;
    dialogMessages: DialogMessages;

    constructor(dialogMessages: DialogMessages) {
        this.fhirService = new FhirService();
        this.dialogMessages = dialogMessages;
    }

    rotations = {
        1: 0,
        3: 180,
        6: 90,
        8: 270
    };

    newImageWidth: number;
    newImageHeight: number;

    async createImageLists() {

    }

    rotate(canvas: HTMLCanvasElement, degrees: number): Promise<string> {
        return new Promise<string>((resolve) => {
            // store current data to an image
            let myImageData = new Image();
            myImageData.src = canvas.toDataURL();

            myImageData.onload = function () {
                // reset the canvas with new dimensions
                let ch = canvas.height;
                let cw = canvas.width;
                canvas.width = ch;
                canvas.height = cw;
                cw = canvas.width;
                ch = canvas.height;
                let context = canvas.getContext("2d");

                context.save();
                // translate and rotate
                context.translate(cw, ch / cw);
                context.rotate(NitTools.GradToRad(degrees));
                // draw the previows image, now rotated
                context.drawImage(myImageData, 0, 0);
                context.restore();

                // clear the temporary image
                myImageData = null;

                resolve(canvas.toDataURL("image/jpeg"));
            };
        });
    }

    _getIcon(source): Promise<string> {
        return new Promise<string>((resolve) => {
            let img = document.createElement("img");
            img.style.display = "block";

            img.onload = () => {
                let cnv = document.createElement("canvas");
                cnv.width = 100;
                cnv.height = 100;

                let f : number;
                ////////////////////
                if (img.width > img.height || img.width === img.height) {
                    f = img.width / cnv.width;
                } else {
                    f = img.height / cnv.height;
                }

                let dw = img.width / f;
                let dh = img.height / f;
                let tx = (cnv.width / 2) - (dw / 2);
                let ty = (cnv.height / 2) - (dh / 2);
                if (tx < 0) tx = 0;
                if (ty < 0) ty = 0;
                let ctx = cnv.getContext("2d");
                ctx.drawImage(img, 0, 0, img.width, img.height, tx, ty, dw, dh);

                resolve(cnv.toDataURL("image/png"));
            };

            img.src = "data:image/png;base64," + source;
        });
    }

    getIcon(source: string, rotation: number): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            try {
                let rotationValue = this.rotations[rotation];
                if (rotationValue) {
                    let i = document.createElement("img");
                    i.onload = () => {
                        let c = document.createElement("canvas");
                        c.width = i.width;
                        c.height = i.height;
                        c.getContext("2d").drawImage(i, 0, 0);
                        this.newImageHeight = i.height;
                        this.newImageWidth = i.width;

                        this.rotate(c, rotationValue).then(result => {
                            source = result;
                            this._getIcon(source).then((result) => resolve(String(result)));
                        });
                    };

                    i.src = source;
                } else {
                    this._getIcon(source).then((result) => resolve(String(result)));
                }
            } catch (e) {
                console.warn(e.message);
                reject("Could not create Thumbnail of image: " + e.message);
            }
        });
    }

    async checkThumbs() {
        this.loading = true;
        let url = `Media?${ConfigService.Tiplu.enabled ? '' : '_format=json&'}type=${FhirService.FhirVersion > 3 ? 'image' : 'photo'}`;
        let images: any[] = await this.fhirService.fetch(url);

        let encounters = [];
        images.forEach(image => {
            let encounterId = (image.context||image["encounter"])?.reference.split('/')[1];
            let existing = encounters.find(o => o.id === encounterId);
            if (!existing) {
                existing = {id: encounterId};
                encounters.push(existing);
            }

            if (!existing.thumbs) existing.thumbs = [];
            if (!existing.images) existing.images = [];
            if (image.width <= 110) {
                existing.thumbs.push(image);
            } else {
                existing.images.push(image);
            }
        });

        encounters.sort((a, b) => {
            return String(a.id).localeCompare(String(b.id));
        });

        encounters.forEach(encounter => {
            // for (let e = 0; e < encounters.length; e++) {
            // let encounter = encounters[e];
            if (encounter.thumbs.length < encounter.images.length) {
                encounter.images.forEach(async (image: any) => {
                    let existing = encounter.thumbs.find((o: any) => o.content.url.endsWith(image.id));
                    if (!existing) {
                        let thumbNailBase64 = await this.getIcon(image.content.data, 0);

                        let thumbnailContent: any = {
                            contentType: 'image/png',
                            data: thumbNailBase64,
                            creation: new Date().toJSON(),
                            title: image.content.title,
                            url: fhirEnums.ResourceType.media + "/" + image.id
                        };

                        let icon: any = {
                            resourceType: fhirEnums.ResourceType.media,
                            id: NitTools.Uid(),
                            text: {
                                status: "generated",
                                div: `<div xmlns="http://www.w3.org/1999/xhtml">${image.text}</div>`
                            },
                            bodySite: image.bodySite,
                            type: fhirEnums.DigitalMediaType.photo,
                            identifier: image.identifier,
                            subject: image.subject,
                            device: image.device,
                            context: image.context,
                            frames: 1,
                            content: thumbnailContent,
                            width: 100,
                            height: 80
                        };

                        const subTypeName = FhirService.FhirVersion > 3 ? 'modality' : 'subtype';
                        icon[subTypeName] = {
                            coding: [
                                {
                                    system: RuntimeInfo.SystemHeader + "/image-thumbnail",
                                    code: "thumbnail"  // External ocular photography for medical evaluation and documentation
                                }
                            ]
                        };

                        icon.identifier.push({
                            system: RuntimeInfo.SystemHeader + "/media-source-id",
                            value: image.id
                        });

                        encounter.thumbs.push(icon);

                        await this.fhirService.update(icon);
                    }
                });
            }
        });

        this.loading = false;
    }

    async checkForBrokenImages() {
        if (this.loading) return;
        this.loading = true;
        let url = `Media?_format=json&type=${FhirService.FhirVersion > 3 ? 'image' : 'photo'}`;
        let images: any[] = await this.fhirService.fetch(url);

        this.loading = false;

        images = images.filter(o => o.content && o.content.data && o.content.data.indexOf('dataimage/pngbase64') === 0);

        if (images.length > 0) {
            this.dialogMessages.dialog(images.length + ' invalid images found.<br />Delete them now?', "Confirm", "Yes", "No", true)
                .whenClosed(async r => {
                    if (r.wasCancelled) {
                        return;
                    } else {
                        this.loading = true;
                        for (let i = 0; i < images.length; i++) {
                            await this.fhirService.delete(images[i]);

                            if (images[i].identifier) {
                                let source = images[i].identifier.find(o => o.system.endsWith('media-source-id'));
                                if (source) {
                                    await this.fhirService.deleteUrl('Media/' + source.value);
                                }
                            }
                        }

                        this.loading = false;
                    }
                });
        } else {
            this.dialogMessages.prompt('No invalid images found.', 'Information');
        }
    }

    deleteImageLists() {
        alert("Delete");
    }
}
