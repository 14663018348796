import {autoinject} from "aurelia-framework";
import {FhirService} from "../../../../resources/services/FhirService";
import {ConfigService} from "../../../../resources/services/ConfigService";
import {LocationService} from "../../../../resources/services/LocationService";
import {fhirEnums} from "../../../../resources/classes/fhir-enums";
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;

@autoinject
export class WoundidCheck {
    str_allWards = "_ALL_WARDS_";
    wards: { name, id }[] = [];
    selectedWardId: string = this.str_allWards;
    status: string;
    subStatus: string;
    info: string;
    abort: boolean = false;
    working: boolean = false;

    get loadAllWards(): boolean {
        return this.selectedWardId === this.str_allWards;
    }

    constructor(protected fhirService: FhirService, protected locationService: LocationService) {
    }

    async attached() {
        await this.locationService.fetch();

        this.locationService.wards.forEach(w => {
            this.wards.push({name: w.name, id: w.id});
        });
    }

    public async startCheck() {
        this.status = '';
        this.subStatus = '';
        this.info = '';
        this.working = true;

        const selectedWards = [];

        if (this.loadAllWards) {
            this.locationService.wards.forEach(w => selectedWards.push(w.id));
        } else {
            selectedWards.push(this.selectedWardId);
        }

        try {
            let totalEncounterCount = 0;
            let updateCount = 0;

            for (const wardId of selectedWards) {
                if (this.abort) throw 'Aborted';
                const preStatus = `Ward ${selectedWards.indexOf(wardId) + 1}/${selectedWards.length}`;

                this.status = `${preStatus} - Loading Encounters (${totalEncounterCount} total)`;
                const encounterUrl = `Encounter?_count=50&_summary=true&location=${wardId}&${ConfigService.cfg["encounterAdditionalFilters"]}`;
                let curCount = 0;
                const encounters: any[] = await this.fhirService.fetch(encounterUrl, true, (bundle, count) => {
                    curCount += count;
                    totalEncounterCount += count;
                    this.status = `${preStatus} - Loaded Bundle with ${curCount} Encounters (${totalEncounterCount} total)`;
                });

                this.status = `${preStatus} - Loaded ${encounters.length} Encounters for ${LocationService.LocationById(wardId)?.name}`;

                for (const encounter of encounters) {
                    this.subStatus = `Loading Wounds-List for Encounter ${encounter.id}`;
                    if (this.abort) throw 'Aborted';
                    const listUrl = `List?encounter=${encounter.id}`;
                    const lists: any[] = await this.fhirService.fetch(listUrl);
                    this.info = '';
                    let responsesCount = 0;
                    for (const list of lists) {
                        if (list.entry) {
                            const updateResources : any[] = [];

                            for (const listEntry of list.entry.filter(
                                o => o.flag && o.flag.coding
                                    && o.flag.coding[0] && o.flag.coding[0].system && o.flag.coding[0].code
                                    && (o.flag.coding[0].system.endsWith('/wound-id') || o.flag.coding[0].system.endsWith('/group-id'))
                                    && o.item && o.item.reference && o.item.reference.indexOf('QuestionnaireResponse/') === 0
                            )) {
                                const woundId = listEntry.flag.coding[0].code;
                                responsesCount++;

                                this.info = `Checking ${responsesCount} Wound-Responses`;
                                let response : any;
                                try {
                                    response = <any>await this.fhirService.get(listEntry.item.reference);
                                }
                                catch (e) {
                                    console.warn(e);
                                    response = undefined;
                                }

                                if (!response) continue;

                                if (!response.extension) {
                                    response.extension = [];
                                }

                                if (!response.extension) response.extension = [];
                                let woundIdIdentifier = response.extension.find(o => o.url.endsWith('/wound-id'));
                                if (!woundIdIdentifier) {
                                    woundIdIdentifier = ({
                                        url: 'http://nursit-institute.com/StructureDefinition/wound-id',
                                        valueId: woundId
                                    });

                                    response.extension.push(woundIdIdentifier);

                                    updateResources.push(response);
                                }

                                if (this.abort) throw 'Aborted';
                            }

                            if (updateResources.length > 0) {
                                updateCount += updateResources.length;
                                this.info = `Updating ${updateResources.length} QRs`;
                                await this.fhirService.bundle(updateResources, HTTPVerb.put, BundleType.transaction, false);
                            }

                            this.info = '';
                        }
                    }
                }
            }

            this.status = "Finished Wound-Id Check for " + selectedWards.length + " Wards";
            this.subStatus = `Updated ${updateCount} Responses`;
        } catch (e) {
            this.subStatus = '';
            this.status = e.message||e;
            throw e;
        } finally {
            this.abort = false;
            this.info = '';
            this.working = false;
        }
    }
}
